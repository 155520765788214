import { createGlobalStyle, css } from 'styled-components'

import { DefaultTheme } from '../../styled-components'

const GlobalStyles = createGlobalStyle(
    ({ theme }: { theme: DefaultTheme }) => css`
        * {
            font-family: ${theme.typography.font.family.latoVerdana};
            font-weight: ${theme.typography.font.lato.weight.regular};
            color: ${theme.colors.tfwBlack87};
        }

        html {
            scroll-behavior: smooth;
            box-sizing: border-box;
        }

        *,
        *::before,
        *::after {
            box-sizing: inherit;
        }

        /* Undo the line-height change from 'normalize.css' */
        html,
        sub,
        sup,
        button,
        input,
        optgroup,
        select,
        textarea {
            line-height: normal;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        li,
        button {
            margin: 0;
            padding: 0;
        }

        input {
            border: none;
            outline: none;
        }

        button,
        input[type='submit'],
        input[type='reset'] {
            background: none;
            color: inherit;
            border: none;
            outline: inherit;
            margin: 0;
            padding: 0;
            cursor: pointer;
        }

        b,
        strong {
            font-weight: ${theme.typography.font.lato.weight.bold};
        }
        .ReactModal__Body--open {
            overflow: hidden !important;
        }
    `,
)

export default GlobalStyles
