import { BuilderMethod } from '../models/types'
import { isServer } from './platform-utils'
import UserDetailsModel from '../models/user/user-details-model'
import LivePricingModel from '../models/pricing/live-pricing-model'

const _transformableModels = new Map<string, BuilderMethod>([
    ['users/user-details', UserDetailsModel.buildFromResponse],
    ['prices/live', LivePricingModel.buildFromResponse],
])

/**
 *
 * @param resourceType The REST resource name that this transport maps to.
 * @param data The actual transport itself.
 */
export const transformTransportData = (resourceType: string | null, data: any[] | any) => {
    if (resourceType && !isServer) {
        const builder = _transformableModels.get(resourceType)
        if (builder) {
            return Array.isArray(data) ? data.map(data => builder(data)) : builder(data)
        }
    }
    return data
}

export const getModel = <T extends {}, M extends {}>(data: T, meta?: { resource?: string }): M => {
    const resourceType = meta && meta.resource ? meta.resource : null
    return transformTransportData(resourceType, data) as M
}
