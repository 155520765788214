import { useCallback, useContext, useMemo } from 'react'
import { IntlMessageFormat } from 'intl-messageformat'
import { LocaleContext } from '../context/locale'
import { defaultMessages } from '../i18n/config'
import { TranslationFunction, FormatValues } from '../common/types'
import get from 'lodash/get'

export const useLocale = () => useContext(LocaleContext)

export const useTranslation = () => {
    const { locale, messages } = useLocale()

    const formatters = useMemo(() => {
        const cache = new Map<string, IntlMessageFormat>()

        return {
            get: (message: string) => {
                if (!cache.has(message)) {
                    cache.set(message, new IntlMessageFormat(message, locale))
                }
                return cache.get(message)!
            },
        }
    }, [locale])

    const t: TranslationFunction = useCallback(
        (key: string, values?: FormatValues): string => {
            let message = get(messages, key)
            if (message === undefined || message === null) {
                console.warn({ message: 'Translation for locale not found.', key, locale })
                message = get(defaultMessages, key, '')
            }

            try {
                const formatter = formatters.get(message as string)
                return formatter.format(values || {}) as string
            } catch (error) {
                console.warn('Error formatting message:', { key, message, values, error })
                return message as string
            }
        },
        [locale, messages, formatters],
    )

    return { t }
}
