// context/QueryContext.tsx

import { createContext } from 'react'

interface QueryContextType {
    queryParams: Record<string, string | null>
}

const QueryContext = createContext<QueryContextType | null>(null)

export default QueryContext
