import { useEffect } from 'react'
import {
    onCLS,
    onINP,
    onLCP,
    CLSMetricWithAttribution,
    INPMetricWithAttribution,
    LCPMetricWithAttribution,
} from 'web-vitals/attribution'
import AnalyticsService from '../services/analytics-service'
import { WebVitalEventParams } from '../services/analytics-service/types'

// Helper function to create the eventParams object
const createEventParams = (
    metric: CLSMetricWithAttribution | INPMetricWithAttribution | LCPMetricWithAttribution,
): WebVitalEventParams => {
    const { name, delta, value, id, attribution } = metric

    const baseParams = {
        name,
        id,
        value,
        delta,
        valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
        deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
    }

    switch (name) {
        case 'CLS':
            return {
                ...baseParams,
                debugTarget: attribution.largestShiftTarget,
                debugSource: attribution.largestShiftSource,
                debugTime: attribution.largestShiftTime,
                debugLoadState: attribution.loadState,
            }
        case 'INP':
            return {
                ...baseParams,
                debugTarget: attribution.interactionTarget,
                debugType: attribution.interactionType,
                debugTime: attribution.interactionTime,
                debugPaintTime: attribution.nextPaintTime,
                debugLoadState: attribution.loadState,
                debugPresentationDelay: attribution.presentationDelay,
                debugProcessingDuration: attribution.processingDuration,
            }
        case 'LCP':
            return {
                ...baseParams,
                debugTarget: attribution.element,
                debugRenderDelay: attribution.elementRenderDelay,
                debugLoadDelay: attribution.resourceLoadDelay,
                debugLoadDuration: attribution.resourceLoadDuration,
                debugUrl: attribution.url,
                debugNavigationEntry: attribution.navigationEntry,
                debugResourceEntry: attribution.lcpResourceEntry,
                debugEntry: attribution.lcpEntry,
                debugFirstByteTime: attribution.timeToFirstByte,
            }
        default:
            throw new Error(`Unsupported metric name: ${name}`)
    }
}

const sendToGoogleAnalytics = (
    metric: CLSMetricWithAttribution | INPMetricWithAttribution | LCPMetricWithAttribution,
) => {
    const eventParams = createEventParams(metric)
    AnalyticsService.trackWebVitalEvent({ name: metric.name, eventParams })
}

const useWebVitals = () => {
    useEffect(() => {
        // Set a timeout to delay the initialization of Web Vitals tracking
        const timeoutId = setTimeout(() => {
            onINP(sendToGoogleAnalytics)
            onCLS(sendToGoogleAnalytics)
            onLCP(sendToGoogleAnalytics)
        }, 0)

        // Clear the timeout when the component unmounts
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])
}

export default useWebVitals
