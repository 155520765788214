import LivePricingResponseTransport, {
    AutoCorrectionTransport,
    OptionalCostTransport,
    InclusiveCostTransport,
    ExclusiveCostTransport,
    SpecialOfferTransport,
    AdditionalServiceTransport,
    ArticleTransport,
    TaxTransport,
} from '../../transports/live-pricing-transport'

class LivePricingModel {
    public readonly fid: string | null
    public readonly autoCorrection: AutoCorrectionTransport | null
    public readonly startDate: string
    public readonly endDate: string
    public readonly adults: number
    public readonly childrenAges: number[]
    public readonly fromPriceActive: boolean | null
    public readonly pets: number
    public readonly price: number
    public readonly billingAmount: number
    public readonly onSiteAmount: number
    public readonly currency: string
    public readonly optionalCosts: OptionalCostTransport[] | null
    public readonly inclusiveCosts: InclusiveCostTransport[] | null
    public readonly exclusiveCosts: ExclusiveCostTransport[] | null
    public readonly usageBoundCosts: string[] | null
    public readonly terms: string | null
    public readonly redirectUrl: string | null
    public readonly specialOffers: SpecialOfferTransport[] | null
    public readonly additionalServices: AdditionalServiceTransport[] | null
    public readonly selectedOptionServices: string[] | null
    public readonly optionalServicesQuantity: number[] | null
    public readonly articles: ArticleTransport[] | null
    public readonly taxes: TaxTransport[] | null

    constructor(data: LivePricingResponseTransport) {
        this.fid = data.fid || null
        this.autoCorrection = data.autoCorrection || null
        this.startDate = data.startDate
        this.endDate = data.endDate
        this.adults = data.adults
        this.childrenAges = data.childrenAges
        this.fromPriceActive = data.fromPriceActive || null
        this.pets = data.pets
        this.price = data.price
        this.billingAmount = data.billingAmount
        this.onSiteAmount = data.onSiteAmount
        this.currency = data.currency
        this.optionalCosts = data.optionalCosts || null
        this.inclusiveCosts = data.inclusiveCosts || null
        this.exclusiveCosts = data.exclusiveCosts || null
        this.usageBoundCosts = data.usageBoundCosts || null
        this.terms = data.terms || null
        this.redirectUrl = data.redirectUrl || null
        this.specialOffers = data.specialOffers || null
        this.additionalServices = data.additionalServices || null
        this.selectedOptionServices = data.selectedOptionServices || null
        this.optionalServicesQuantity = data.optionalServicesQuantity || null
        this.articles = data.articles || null
        this.taxes = data.taxes || null
    }

    public static buildFromResponse(data: LivePricingResponseTransport): LivePricingModel {
        return new LivePricingModel(data)
    }
}

export default LivePricingModel
