import React, { FC, useCallback, useEffect, useState } from 'react'

import { getLocaleFromLanguage } from '../i18n/utils'
import LocaleProvider from '../i18n/provider'
import { RequestUserConfigContextProvider } from '../context/request-user-config'
import { RequestUserConfig, SessionConfig, TokenConfig } from '../common/types'
import { Messages } from '../i18n/types'
import { Routes } from '../routes/types'
import RoutesProvider from '../routes/provider'
import QueryProvider from './query'
import { NetworkService } from '../services'

interface Props {
    requestUserConfig: RequestUserConfig
    messages: Messages
    routes: Routes
}

const UserSettingsConfigProvider: FC<Props> = ({ requestUserConfig, messages, routes, children }) => {
    const [updatedRequestUserConfig, setUpdatedRequestUserConfig] = useState<RequestUserConfig>(requestUserConfig)

    const fetchSession = useCallback(async (): Promise<void> => {
        try {
            const response = await NetworkService.get<any>({
                url: '/session',
                completeResponse: true,
            })

            const xsrfToken = response.headers['x-xsrf-token']
            const laravelSession = response.headers['x-session-id']
            if (laravelSession && xsrfToken) {
                const tokenConfig: TokenConfig = {
                    _token: xsrfToken,
                }

                const sessionConfig: SessionConfig = {
                    id: laravelSession,
                    config: tokenConfig,
                }

                setUpdatedRequestUserConfig(prevConfig => ({
                    ...prevConfig,
                    session: sessionConfig,
                }))
            } else {
                console.error('laravel_session or XSRF-TOKEN cookie not found in headers')
            }
        } catch (error) {
            console.error('Error setting session:', error)
        }
    }, [])

    useEffect(() => {
        fetchSession()
    }, [])

    return (
        <RequestUserConfigContextProvider value={updatedRequestUserConfig}>
            <LocaleProvider
                initialLocale={getLocaleFromLanguage(updatedRequestUserConfig.language)}
                initialMessages={messages}
            >
                <RoutesProvider initialRoutes={routes}>
                    <QueryProvider>{children}</QueryProvider>
                </RoutesProvider>
            </LocaleProvider>
        </RequestUserConfigContextProvider>
    )
}

export default UserSettingsConfigProvider
