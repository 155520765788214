// context/QueryContext.tsx

import React, { ReactNode, useState, useEffect } from 'react'
import QueryContext from '../context/query'

const QueryProvider = ({ children }: { children: ReactNode }) => {
    const [queryParams, setQueryParams] = useState<Record<string, string | null>>({})

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        const params: Record<string, string | null> = {}
        query.forEach((value, key) => {
            params[key] = value
        })
        setQueryParams(params)
    }, [])

    return <QueryContext.Provider value={{ queryParams }}>{children}</QueryContext.Provider>
}

export default QueryProvider
